<template>
<div class="main">
  <Tree service="/sys/sysOrganization/dept" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
    <vxe-modal ref="xModal" title="开通账户" width="600" show-zoom>
      <template v-slot>
        <Account :selectRow="selectRow" />
      </template>
    </vxe-modal>
  </div>
</div>
</template>

<script>
import { mapActions } from 'vuex';
import Account from './Account'
export default {
  components: {
    Account
  },
  data() {
    return {
      selectRow: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [
            { code: "create", name: "新增", status: "primary" },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: 'name',
            title: '职工名称',
            minWidth: 160,
            editRender: {
              name: 'input'
            }
          },
          {
            field: "sex",
            title: "性别",
            width: 80,
            editRender: {
              name: "$select",
              optionTypeCode: "sex",
            },
          },
          {
            field: "positionId",
            title: "所属岗位",
            minWidth: 120,
            editRender: {
              name: "$select",
            },
          },
          {
            field: "phone",
            title: "联系电话",
            minWidth: 120,
            editRender: {
              name: "input"
            },
          },
          {
            field: "code",
            title: "账户名称",
            width: 160,
          },
          // {
          //   field: "permanentAddress",
          //   title: "所属地区",
          //   minWidth: 120,
          //   editRender: {
          //     name: "input"
          //   },
          // },
          {
            field: "enabled",
            title: "状态",
            width: 120,
            cellRender: {
              name: "$switch",
              optionTypeCode: "enabledStatus",
              props: {
                openLabel: "启用",
                closeLabel: "禁用",
                openValue: "1",
                closeValue: "0"
              },
              events: {
                change: ({ row }) => {
                  this.$refs.xGrid.submitEvent(row);
                }
              }
            },
          },
          {
            field: "updateTime",
            title: "更新日期",
            width: 200,
          },
          {
            fixed: "right",
            title: "操作",
            width: 200,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  name: "openAccount",
                  status: "primary",
                  label: "开通账户",
                  events: {
                    click: (row) => {
                      if (row.code) {
                        this.$message.success("该职工已经开通账户");
                        return;
                      }
                      this.selectRow = row;
                      this.$refs.xModal.open();
                    }
                  }
                },
                {
                  name: "edit",
                  status: "primary",
                  label: "修改",
                  eventName: "queryEvent",
                },
                {
                  name: "delete",
                  status: "danger",
                  label: "删除",
                  eventName: "removeEvent",
                },
              ],
            },
          },
        ],
        treeConfig: {
          children: "children",
          accordion: false,
        },
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "organizationId",
            title: "所属上级",
            span: 24,
            itemRender: {
              name: "$VxeSelectParent",
              expandAll: true,
              props: {
                placeholder: "请选择所属上级",
              },
              events: {
                click: (params, node) => {
                  let { data, property } = params;
                  data[property] = node.id;
                },
              }
            },
          },
          {
            field: "name",
            title: "职工名称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入职工名称",
              },
            },
          },
          {
            field: "positionId",
            title: "所属岗位",
            span: 12,
            itemRender: {
              name: "$select",
              props: {
                placeholder: "请选择所属岗位",
              },
              events: {
                change: (params, node) => {
                  let { data } = params;
                  data.positionName = node.name;
                }
              }
            },
          },
          {
            field: "sex",
            title: "性别",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "sex",
              props: {
                placeholder: "请选择性别",
              },
            },
          },
          {
            field: "phone",
            title: "联系电话",
            span: 12,
            itemRender: {
              name: "$VxePhoneInput",
              props: {
                required: true,
                placeholder: "请输入联系电话",
              },
            },
          },
          {
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "党员信息",
                position: "left"
              }
            },
          },
          {
            field: "partyMemberType",
            title: "党员类型",
            span: 12,
            itemRender: {
              name: '$select',
              optionTypeCode: "partyMemberType",
              props: {
                placeholder: "请选择党员类型",
              },
              events: {
                change: ({ data, property }) => {
                  if (data[property] == "0") {
                    data["joinPartyTime"] = ""
                    data["partyPosts"] = ""
                  }
                }
              }
            },
          },
          {
            field: "joinPartyTime",
            title: "入党日期",
            span: 12,
            visibleMethod({ data }) {
              if (data.partyMemberType == 0) {
                return false
              } else {
                return true
              }
            },
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择入党日期",
              },
            },
          },
          {
            field: "partyPosts",
            title: "党内职务",
            visibleMethod({ data }) {
              if (data.partyMemberType == 0) {
                return false
              } else {
                return true
              }
            },
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入党内职务",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态",
              },
            },
          },
          {
            field: 'remark',
            title: '备注',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: '请输入备注内容'
              }
            }
          },
          {
            span: 24,
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          organizationId: [
            { required: true, message: "请选择所属上级" },
          ],
          name: [
            { required: true, message: "请输入职工名称" },
          ],
          positionId: [
            { required: true, message: "请选择所属岗位" },
          ],
          sex: [
            { required: true, message: "请选择性别" },
          ],
          enabled: [{ required: true, message: "请选择状态" }],
        },
        // 新增功能字段初始化
        data: {
          name: "",
          code: "",
          sex: "1",
          type: "1",
          positionId: "",
          partyMemberType: "0",
          joinPartyTime: "",
          orderCode: "0",
          enabled: "1",
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "name",
            title: "职工姓名",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入职工姓名",
              },
            },
          },
          {
            field: "phone",
            title: "联系电话",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入联系电话",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态",
              },
            },
          },
          {
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          enabled: "",
        },
      },
    };
  },

  methods: {
    ...mapActions(['findByEventService']),
    handleNodeClick(treeData, node) {
      this.getPosts(node);
      this.currNode = node;
      let { items, data } = this.formOptions;
      this.$Tools.buildDataToGrid(items, treeData, "organizationId");
      data['parentId'] = node.id;
      data['organizationId'] = node.id;
      let { xGrid } = this.$refs;
      xGrid.searchOptions.data["organizationId"] = node.id;
      xGrid.getLists();
    },

    // 获取岗位
    getPosts(node) {
      this.findByEventService({
          service: "/sys/sysPosition",
          params: {
            parentId: node.id,
            enabled: '1'
          }
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.gridOptions.columns = [...this.$Tools.buildDataToGrid(this.gridOptions.columns, data, "positionId", { value: "id", label: "name" })];
            this.formOptions.items = [...this.$Tools.buildDataToGrid(this.formOptions.items, data, "positionId", { value: "id", label: "name" })];
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
