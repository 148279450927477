<template>
<div class="main">
  <div class="main_right">
    <vxe-form ref="xForm" title-width="100" :items="formOptions.items" :data="formData" v-loading="loading" :rules="formOptions.rules" @submit="submitEvent" @reset="closeModal"></vxe-form>
  </div>
</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["selectRow"],
  data() {
    return {
      loading: false,
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: 'roleIds',
            title: '所属角色',
            span: 24,
            itemRender: {
              name: "$VxeSelectRole",
              props: {
                clearable: true,
                placeholder: '请选择所属角色'
              },
              events: {
                click: ({ data, property }, node) => {
                  data[property] = [node]
                }
              }
            }
          },
          {
            field: "account",
            title: "账户名称",
            span: 24,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入账户名称",
              },
            },
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          account: [
            { required: true, message: "请输入账户名称" },
          ],
          roleIds: [
            { required: true, message: "请选择用户角色" },
            {
              validator({ itemValue }) {
                if (itemValue && !itemValue.length) {
                  return new Error('请选择用户角色!');
                }
              },
              trigger: 'change'
            }
          ],
        },
      },

      // 新增功能字段初始化
      formData: {
        organizationId: this.selectRow.organizationId,
        roleIds: [],
        account: "",
      },
    };
  },
  methods: {
    ...mapActions([
      "submitEventService",
    ]),

    // 提交数据
    submitEvent() {
      this.$refs["xForm"].validate((valid) => {
        if (!valid) {
          this.loading = true;
          this.formData.workersId = this.selectRow.id;
          this.submitEventService({
            service: "/sys/sysWorkers/createAccount",
            params: this.formData,
          }).then(({ code, data }) => {
            if (code == 200) {
              this.$parent.$parent.$refs.xGrid.getLists();
              this.$message.success("账户创建成功");
              this.closeModal();
            }
            this.resetEvent();
          });
        }
      });
    },

    resetEvent() {
      this.loading = false;
    },

    // 关闭模态对话框
    closeModal() {
      this.$parent.$parent.$refs.xModal.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
